'use client';

import Link from 'next/link';
import { useEffect } from 'react';
import { Button } from '../components';
import { Div, H3, P, PageWrapper } from '../styles';
import * as Sentry from '@sentry/nextjs';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <PageWrapper>
      <Div $flex $center $column>
        <Div $textAlignCenter>
          <H3 $center>Error!</H3>
          <P>Something went wrong</P>
          <Link href="/">
            <Button onClick={() => reset()}>Try again</Button>
          </Link>
        </Div>
      </Div>
    </PageWrapper>
  );
}
